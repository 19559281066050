.login-section {
    display: flex;
}

.login-hero-image-container {
    display: flex;
    justify-content: center;
    max-height: 412px;
}

.login-hero-image-container img {
    object-fit: contain;
}