/* @import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;600;700&display=swap'); */
/* @import url(./components/dropdown.css);
@import url(./components/button.css);
@import url(./components/accordion.css);
@import url(./components/modal.css);
@import url(./components/table.css);
@import url(./components/alerts.css);
@import url(./components/tabs.css);
@import url(./components/badge.css);
@import url(./components/checkbox.css); */
@import url(./components/card.css);
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.2/font/bootstrap-icons.min.css");


.hr-light-full {
    display: block;
    width: 100%;
    padding: 0px;
    margin: 25px 0px;
}

/* * {
    font-family: "Nunito", Helvetica, Arial, sans-serif;
} */