h1 {
    font-size: 34px;
    font-weight: 500;
    color: var(--bs-heading-color);
    margin-bottom: 5px;
    line-height: 1.2;
}

h2 {
    font-size: 2rem;
    font-weight: 500;
    color: inherit;
    line-height: 1.2;
}

h4 {
    color: var(--steel);
    font-size: calc(1.275rem + .3vw);
    font-weight: 500;
    line-height: 1.2;
}

h5 {
    font-size: 1.25rem;
    font-weight: 500;
    color: var(--bs-heading-color);
}

h6 {
    font-size: 1rem;
    color: var(--grey);
    font-weight: 500!important;
}

p {
    /* color: var(--med-grey); */
    /* line-height: 18px; */
    font-weight: 300;
    margin-top: 0;
    margin-bottom: 1rem;
}

.fc-blue {
    color: var(--blue);
}

.fc-light {
    color: var(--med-grey);
}

.fw-light {
    font-weight: 300 !important;
}

span.sec-text {
    min-width: 100%;
    font-size: 14px;
    text-align: center;
    color: #808284;
}

.small, small {
    font-size: .875em !important;
}