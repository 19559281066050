.input-group.chatInput {
    padding: 0px;
    background-color: transparent;
    height: 60px;
    border-radius: 0px;
    border: 1px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.input-group.chatInput .form-control {
    font-size: 18px;
    background-color: transparent;
    border-radius: 0px !important;
    border: none;
    height: 60px;
    width: 100%;
    transition: 0.4s;
    padding: 0px 0px 0px 20px;
    margin-right: 60px;
}

.input-group .send-chat-button {
    position: absolute;
    right: 25px;
}

.send-chat-button i::before {
    transform: rotate(45deg);
    font-size: 25px;
    color: #d9d9d9;
    transition: 0.3s;
}

.send-chat-button:hover i::before {
    transform: rotate(0deg);
    color: #661aff;
    transition: 0.3s;
}

.input-group.chatInput:has(.form-control:focus) {
    background-color: transparent;
    outline: 0;
    border-top: 1px solid #b5aacc;
    border-bottom: 1px solid #b5aacc;
    transition: 0.4s;
    box-shadow: none;
}

.input-group.chatInput .form-control:focus {
    box-shadow: none;
}

.input-group.chatInput a.btn i.bi::before {
    transform: rotate(45deg);
    font-size: 25px;
    margin-top: 8px;
    color: #d9d9d9;
    transition: 0.3s;
}

.input-group.chatInput a.btn:hover i.bi::before {
    transform: rotate(0deg);
    color: #661aff;
    transition: 0.3s;
}

.input-group.chatInput .form-control::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: #C4C4C4;
}

.input-group.chatInput .form-control:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #C4C4C4;
    opacity: 1;
}

.input-group.chatInput .form-control::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #C4C4C4;
    opacity: 1;
}

.input-group.chatInput .form-control:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #C4C4C4;
}

.input-group.chatInput .form-control::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #C4C4C4;
}

.input-group.chatInput .form-control::placeholder {
    /* Most modern browsers support this now. */
    color: #C4C4C4;
}