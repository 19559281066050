.card {
    border-radius: 12px;
    border-color: var(--slight-grey);
}

.card-spacing-20-40 {
    padding: 20px 40px;
}

.card.bg-primary {
    /* color: var(--bs-card-color) !important; */
    border-color: #3786ee !important;
    background-color: #f5faff !important;
}

.card.bg-primary p span {
    color: #4285f4 !important;
}

.divider {
    width: 1px;
    height: 20px;
    position: relative;
    background-color: var(--grey);
}

/* chatCard */
.chatCard {
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
    border: none;
    background-color: white;
    border-radius: 14px;
    overflow: hidden;
    margin-bottom: 25px;
    height: calc(100% - 25px);
    transition: 0.4s;
    border-bottom: 2px solid #ababb8;
    cursor: pointer;
}

.chatCard h6 {
    color: #6619ff;
    text-decoration: none;
}

.chatCard h3 {
    font-weight: 300;
    font-size: 26px;
    line-height: 26px;
    margin-top: -5px;
}

.chatCard .card-body {
    padding: 20px 30px 10px 30px;
}

.chatCard .card-footer {
    background-color: transparent;
    border: none;
    padding: 0px 30px 20px 16px;
}

.chatCard .card-footer .btn-link {
    display: flex;
    align-items: center;
    color: black;
    text-decoration: none;
    height: 38px;
    transition: 0.4s;
    font-weight: 400;
    font-size: 1rem;
    padding: 0.375rem 0.75rem;
}


.chatCard .card-footer .btn-link::before {
    content: '';
    background-image: url(/public/assets/icons/chevron-right.svg);
    width: 15px;
    height: 15px;
    margin-right: 10px;
    top: 5px;
    opacity: 0.4;
    filter: invert(1);
}

.chatCard:hover {
    box-shadow: 0px -2px 8px 0px rgba(0, 0, 0, 0.1);
    background-color: #efeffd;
    transition: 0.4s;
}

.chatCard:hover .card-footer .btn-link {
    transform: translateX(10px);
    transition: 0.4s;
}