.recursosTable-title {
    font-size: 13px;
    margin: 0px;
    color: #AF9CA8;
    font-weight: 400;
}

.recursosTable-text {
    font-size: 15px;
    margin: 0px;
    /* line-height: 15px; */
    line-height:22px;
}


.recursosTable-text:has(+ .small) {
    margin-top: 4px;
    line-height:15px;

}

.recursosTable-text.small {
    font-size: 13px !important;
    line-height: 15px !important;
    color: #63738C !important;
    margin-top: 5px !important;
    margin-bottom: 1px !important;
    line-height:15px;
}

.recursosTable-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #F8ECFE;
    padding: 15px 20px 10px 8px;
}