:root {
    --bs-navbar-nav-link-padding-x: 0.5rem;
    --bs-navbar-toggler-padding-y: 0.25rem;
    --nav-width: 85px;
    --main-margin-left: 105px;
    --main-margin-right: 60px;
    --main-width: 255px;

    /* calc(105 + 65 + var(--nav-width)) */

}